.footer {
    background-color: rgba(2, 10, 32, 0.6);
    display: flex;
    justify-content: center;
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    @media screen and (max-width: 768px) {
        font-size: 15px;
    }
    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
}

.footer p {
    color: #00ffad;
}