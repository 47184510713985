.outer-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/banner-bk.jpg);

    @media screen and (max-width: 768px) {
        font-size: 15px;
    }
    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
}

.article-container {
    background-color: #e0fbf2;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    width: 90%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.article-container h3 {
    font-weight: bold;
}

.read-more-button {
    padding: 10px;
    background-color: #192440;
    color: #fff;
    border: none;
    border-radius: 30px 30px 30px 30px;
    font-size: 13px;
    cursor: pointer;
    width: fit-content;
}

.nav {
    height: 80px;
    background-color: #192440;
    display: flex;
    align-items: center;
}

.nav a {
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
    font: "Josefin Sans";
    margin-left: 20px;
}

.nav a span {
    font-size: 1.9rem;
}