.aboutUsWrapper {
    padding: 10px;
    background-color: white;
}

.aboutUsContainer {
    width: 60%;
    margin: 0 auto;
    padding: 10px;
    @media screen and (max-width: 1000px) {
        width: 70%;
    }
    @media screen and (max-width: 684px) {
        width: 90%;
    }
    @media screen and (max-width: 505px) {
        padding: 0;
        width: 100%;
    }
}

.aboutUsContainer ul {
    padding-left: 30px;
    margin-top: 10px;
}

.aboutUsContainer ul li{
    list-style-type: disc;
}

.aboutUsContainer h1 {
    font-size: 35px;
    font-weight: bolder;
    @media screen and (max-width: 900px) {
        font-size: 30px;
    }
    @media screen and (max-width: 505px) {
        font-size: 25px;
    }
}

.aboutUsContainer h3 {
    margin-top: 10px;
    font-size: 27px;
    font-weight: bold;
    @media screen and (max-width: 900px) {
        font-size: 25px;
    }
}

.aboutUsContainer h6 {
    margin-top: 10px;
    font-size: 17px;
    font-weight: bold;
    @media screen and (max-width: 900px) {
        font-size: 15px;
    }
}