@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
.earning-call-card {
    width: 80%;
    background-color: white;
    border-radius: 5px;
    margin: 10px 0;
    cursor: pointer;
    padding: 15px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        font-size: 15px;
    }
    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
}

.heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heading span {
    font-size: 14px;
    font-weight: bold;
}

.earning-call-card1 {
    width: 80%;
    background-color: white;
    border-radius: 5px;
    margin: 10px 0;
    padding: 15px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        font-size: 15px;
    }
    @media screen and (max-width: 480px) {
        font-size: 12px;
    }
}
