.home-container {
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/banner-bk.jpg);
    position: relative;
}

.inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 50px; */
}

.search-container {
    max-width: 1000px;
    width: 60%;
    display: flex;
    gap: 10px;
    padding: 10px;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;

    @media screen and (max-width: 1055px ) {
        width: 80%;
    }
    @media screen and (max-width: 586px ) {
        flex-direction: column;
    }
}

input {
    width: 30%;
    padding: 10px 15px;
    font-size: 16px;
    background-color: rgb(240, 238, 238); 
    color: black;
    border: none;
    border-radius: 4px;

    @media screen and (max-width: 806px ) {
        font-size: 13px;
    }
    @media screen and (max-width: 446px ) {
        width: 100%;
    }
}

.fields {
    width: 100%;
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 446px ) {
        flex-direction: column;
        gap: 6px;
    }
}

.search-container button {
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00ffad;
    font-weight: bold;
    @media screen and (max-width: 586px ) {
        width: 40%;
        margin: 0 auto;
    }
}

.search-container button:hover {
    background-color: #12dc9c;
    transition: all ease-in-out 0.5s;
}