@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

.nav-container {
    height: 80px;
    background-color: #192440;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 500px) {
        flex-direction: column;
        gap: 5px;
    }
}

.mobile {
    width: 100%;
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    width: 15rem;
    padding: 0 10px;
    @media screen and (max-width: 682px) {
        width: 11rem;
    }
    @media screen and (max-width: 500px) {
        justify-content: center;
        padding-top: 5px;
    }
}

.logo a {
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
    font: "Josefin Sans";

    @media screen and (max-width: 1024px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 682px) {
        font-size: 0.9rem;
    }
}

.logo a span {
    font-size: 1.9rem;
    @media screen and (max-width: 682px) {
        font-size: 1.4rem;
    }
}

.menu {
    width: 100%;
    display: flex;
    align-items: center;
}

.menu .menu-items {
    display: flex;
    list-style: none;
    width: 100%;
    gap: 50px;
    padding: 10px;
    margin-left: 30px;

    @media screen and (max-width: 856px) {
        font-size: 0.8rem;
    }

    @media screen and (max-width: 682px) {
        margin-left: 0;
        gap: 20px;
    }

    @media screen and (max-width: 500px) {
        justify-content: space-around;
    }

    @media screen and (max-width: 359px) {
        font-size: 0.7rem;
        gap: 10px;
    }
}

.menu .menu-items li a {
    text-decoration: none;
    color: grey;
}

.menu .menu-items li a:hover {
    color: #00ffad;
    transition: all 0.2s
}

.calendar-icon {
    height: 3.5rem;
    cursor: pointer;
    padding-right: 10px;

    @media screen and (max-width: 856px) {
        height: 3rem;
    }

    @media screen and (max-width: 682px) {
        height: 2.5rem;
    }

    @media screen and (max-width: 359px) {
        height: 2.3rem;
    }
}

.react-calendar {
    position: absolute;
    top: 35px;
    right: 25px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
    background-color: rgb(238, 231, 231);
    border: none;
    @media screen and (max-width: 500px) {
        right: 15px;
        top: 40px;
    }
    @media screen and (max-width: 400px) {
        width: 300px;
        font-size: 12px;
    }
    @media screen and (max-width: 350px) {
        width: 260px;
    }
}

.react-calendar__tile {
    color: #333;
    font-weight: bold;
    text-align: center;
    @media screen and (max-width: 400px) {
        font-size: 12px;
    }
}

.react-calendar__tile--active {
    background-color: #007bff;
    color: #fff;
}

.react-calendar__navigation button {
    background-color: #80f4cf;
    color: black;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    @media screen and (max-width: 400px) {
        font-size: 12px;
    }
    @media screen and (max-width: 350px) {
        font-size: 10px;
    }
}

