@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
@import url("https://fonts.googleapis.com/css?family=Encode+Sans+Expanded");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Encode Sans Expanded", sans-serif;
}

a {
    text-decoration: none;
}

ul, li {
    list-style: none;
}

p {
    margin-top: 5px;
}