.contact-us {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
    min-height: 100vh;
    @media screen and (max-width: 480px) {
        padding: 0;
    }
}

.contact-us-container {
    background-color: #192440;
    width: 800px;
    padding: 25px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    border-radius: 20px;
    gap: 50px;
    @media screen and (max-width: 821px) {
        grid-template-columns: repeat(1, minmax(0px, 1fr));
        width: 450px;
    }
    @media screen and (max-width: 480px) {
        width: 100%;
        border-radius: 0;
    }
}

.contact-us-left {
    padding: 30px;
    color: white;
    @media screen and (max-width: 480px) {
        padding: 0;
    }
}
  
.contact-us-title {
    font-size: 32px;
    font-weight: bolder;
    margin-bottom: 20px;
}
  
.contact-us-description {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
}
  
.contact-us-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 14px;
}
  
.contact-us-info-item {
    display: flex;
    gap: 5px;
    flex-direction: column;
    padding: 7px 0;
    justify-content: space-between;
}
  
.contact-us-info-value {
    color: white;
}
  
.contact-us-form {
    border-radius: 12px;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
  
.contact-us-form-group {
    margin-bottom: 10px;
}
  
.contact-us-form-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}
  
.contact-us-form-input,
.contact-us-form-textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    background-color: white;
    border: 1px solid rgb(182, 182, 182);
  }
  
.contact-us-form-textarea {
    height: 100px;
    resize: none;
}
  
.contact-us-form-button {
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    background-color: #192440;
    color: #fff;
    cursor: pointer;
}